<template>
  <div>
    <b-card>
      <filter-swapper
        v-if="!loading.carriers"
        :controlHeightButtons="controlHeight"
      >
        <template #slot1>
          <form-render
            :key="keyFormRender"
            :fields="fields"
            :buttonSend="buttonSend"
            class="mb-2"
            :form.sync="formFilter"
            @send="filterList"
            containerButtonsClass="col-sm-12 col-md-8 col-lg-3 container-button mt-2"
          >
            <template #buttons>
              <b-button
                variant="outline-light"
                v-b-tooltip.hover
                title="Limpiar filtros"
                class="ml-2"
                @click="cleanFilter"
                ><feather-icon icon="RefreshCwIcon"
              /></b-button>
            </template>
          </form-render>
        </template>
      </filter-swapper>
      <div v-show="!loading.carriers">
        <table-render 
          id="table-carriers-client" 
          :schema="schema" 
          :rows="rowsWithFilters" 
          :actions="actions"
          :loading="loading.carriers"
        >
        </table-render>
      </div>
      <b-skeleton
        height="40px"
        width="100%"
        class="mt-2 mb-2 spacing-label-field"
        v-show="loading.carriers"
      />
      <div class="table-render-skeleton" v-show="loading.carriers">
        <b-skeleton-table
          :rows="10"
          :columns="schema.length || 10"
          :table-props="{}"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'carriers',
  data() {
    return {
      schema: [],
      rows: [],
      rowsWithFilters: [],
      actions: [],
      fields: [],
      loading: {
        carriers: true
      },
      controlHeight: { class: 'row mb-2 spacing-label-field' },
      keyFormRender: 0,
      buttonSend: { title: 'Buscar', icon: 'SearchIcon', color: 'warning' },
      formFilter: {}
    }
  },
  mounted() {
    this.setInitialData()
  },
  computed: {
    ...mapGetters({
      carriersNY: 'getCarriersNY',
      countries: 'getCountries'
    })
  },
  watch: {
    carriersNY() {
      this.rows = this.carriersNY.rows
      this.rowsWithFilters = this.carriersNY.rows
      this.setCountries()
    },
    countries() {
      this.setCountries()
    }
  },
  methods: {
    setInitialData() {
      this.actions = [
        {
          action: id => this.goToServices(id),
          icon: 'GlobeIcon', color: 'success', text: 'Servicios'
        }
      ]
      this.schema = [
        { label: 'Pais', sortable: true, key: 'countryCode' },
        { label: 'Nombre', sortable: true, key: 'name' },
        { label: 'Codigo de Courier', sortable: true, key: 'code' },
        { label: 'Opciones', key: 'actions', class: ['text-center'] }
      ]
      this.fields = [
        { fieldType: 'FieldSelect', name: 'countryCode', label: 'Pais', containerClass: 'col-sm-12 container-info col-md-3' },
        { fieldType: 'FieldInput', name: 'name', label: 'Courier', containerClass: 'col-sm-12 container-info col-md-3' }
      ]
      this.getCarriersNY()
    },
    setCountries() {
      if (!!this.countries.length && !!this.rowsWithFilters.length) {
        const index = this.fields.findIndex(el => el.name === 'countryCode')
        this.fields[index].options = this.countries.map(value => ({id: value.id, code: value.code, text: value.text}))
        this.rowsWithFilters.map(el => this.countries.map(country => (country.code === el.countryCode) && (el.countryCode = country.name)))
        this.loading.carriers = false
      }
    },
    getCarriersNY() {
      this.$store.dispatch('fetchService', { name: 'getCarriersNY' })
    },
    goToServices(id) {
      this.$router.push({ name: 'carrier-services', params: { id }})
    },
    filterList(form) {
      this.rowsWithFilters = this.rows
      this.rowsWithFilters = (form.countryCode) ? (this.rowsWithFilters.filter(row => row.countryCode === form.countryCode.text)) : this.rowsWithFilters
      this.rowsWithFilters = form.name ? this.rowsWithFilters.filter(row => row.name.toLowerCase().includes(form.name.toLowerCase()) || row.code.toLowerCase().includes(form.name.toLowerCase())) : this.rowsWithFilters
    },
    cleanFilter() {
      this.formFilter = {}
      this.keyFormRender++
      this.filterList(this.formFilter)
      this.getCarriersNY()
    }
  }
}
</script>
